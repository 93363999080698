var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticStyle:{"max-width":"100vw"}},[_c('v-navigation-drawer',{staticStyle:{"top":"0px","max-height":"100vh!important"},attrs:{"right":"","clipped":_vm.$vuetify.breakpoint.lgAndUp,"app":""},model:{value:(_vm.filters),callback:function ($$v) {_vm.filters=$$v},expression:"filters"}},[_c('filterCollections',{attrs:{"company":_vm.company},on:{"filtersCollection":_vm.filtersCollection}})],1),(_vm.company==undefined)?_c('totals'):_vm._e(),_c('v-toolbar',{attrs:{"flat":""}},[_c('v-toolbar-title',[_vm._v("Cobranza "),_c('br'),(_vm.filterStorage())?_c('span',{staticStyle:{"font-size":"12px","color":"grey","position":"absolute"}},[_vm._v("Mes Actual")]):_vm._e()]),(_vm.filterStorageLength== undefined)?_c('v-btn',{staticClass:"ml-6 pt-2",attrs:{"icon":""},on:{"click":function($event){return _vm.openFilter()}}},[_c('v-icon',[_vm._v("mdi-filter")])],1):_c('v-btn',{staticClass:"ml-6",attrs:{"icon":""},on:{"click":function($event){return _vm.openFilter()}}},[_c('v-badge',{attrs:{"overlap":"","color":"primary"},scopedSlots:_vm._u([{key:"badge",fn:function(){return [_vm._v(" "+_vm._s(_vm.filterStorageLength)+" ")]},proxy:true}])},[_c('v-icon',[_vm._v("mdi-filter")])],1)],1),_c('v-spacer'),_c('v-btn',{attrs:{"icon":""},on:{"click":_vm.exportExcel}},[_c('v-icon',[_vm._v("mdi-download")])],1)],1),(_vm.showTable && _vm.tableGraph)?_c('v-data-table',{staticClass:"elevation-0 px-6 py-4",attrs:{"height":"600","fixed-header":"","footer-props":{'items-per-page-options':[15, 30, 50, 100]},"options":_vm.options,"server-items-length":_vm.totalCollections,"loading":_vm.loading,"item-class":_vm.itemRowBackground,"headers":_vm.headers,"items":_vm.collections,"show-expand":""},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [_c('td',{staticClass:"pa-4",attrs:{"colspan":headers.length}},[(item.details!=undefined)?_c('v-data-table',{staticClass:"elevation-0",staticStyle:{"background":"#f7f7f7"},attrs:{"headers":_vm.headers2,"items":item.details,"dense":""},scopedSlots:_vm._u([{key:"item.sale.invoice",fn:function(ref){
var item = ref.item;
return [(item.sale.type=='Serie A')?_c('span',[_vm._v("F-"+_vm._s(item.sale.invoice))]):(item.sale.type=='Serie B')?_c('span',[_vm._v("R-"+_vm._s(item.sale.invoice))]):_vm._e()]}},{key:"item.id",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s((item.sale.id))+" ")]}},{key:"item.due",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s((item.due*1).toLocaleString('es-MX', { style: 'currency', currency: 'MXN',}))+" ")]}},{key:"item.amount",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s((item.amount*1).toLocaleString('es-MX', { style: 'currency', currency: 'MXN',}))+" ")]}},{key:"item.new_due",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(((item.due*1)-(item.amount*1)*1).toLocaleString('es-MX', { style: 'currency', currency: 'MXN',}))+" ")]}}],null,true)}):_vm._e()],1)]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [(_vm.permissions('editCollections')==true || _vm.permissions('deleteCollections')==true)?_c('v-menu',{attrs:{"bottom":"","left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-dots-vertical")])],1)]}}],null,true)},[_c('v-list',{staticStyle:{"font-size":"13px"}},[_c('v-list-item',{directives:[{name:"show",rawName:"v-show",value:(_vm.permissions('editCollections')),expression:"permissions('editCollections')"}],on:{"click":function($event){return _vm.editItem(item.editedItem)}}},[_c('v-icon',{staticClass:"mr-2",attrs:{"small":""}},[_vm._v(" mdi-pencil ")]),_vm._v(" Editar ")],1),_c('v-list-item',{directives:[{name:"show",rawName:"v-show",value:(_vm.permissions('deleteCollections')),expression:"permissions('deleteCollections')"}],on:{"click":function($event){return _vm.deleteItem(item)}}},[_c('v-icon',{staticClass:"mr-2",attrs:{"small":""}},[_vm._v(" mdi-delete ")]),_vm._v(" Eliminar ")],1)],1)],1):_vm._e()]}},{key:"item.macro",fn:function(ref){
var item = ref.item;
return [(item.macro==true)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({},'v-icon',attrs,false),on),[_vm._v("mdi-check")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.payment_complement))])]):_c('v-menu',{attrs:{"offset-y":"","close-on-content-click":false},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({},'v-icon',attrs,false),on),[_vm._v("mdi-close")])]}}],null,true)},[_c('v-card',{staticClass:"pa-4 pb-3",attrs:{"width":"200px"}},[_c('v-text-field',{staticClass:"pb-0 mb-0",attrs:{"label":"Complemento de Pago"},model:{value:(_vm.complemento),callback:function ($$v) {_vm.complemento=$$v},expression:"complemento"}}),_c('v-btn',{staticClass:"pt-0 mt-0",attrs:{"text":"","small":"","color":"primary","rounded":""},on:{"click":function($event){return _vm.macroCapture(item.id,1)}}},[_vm._v(" Guardar ")])],1)],1)]}},{key:"item.methods",fn:function(ref){
var item = ref.item;
return _vm._l((item.methods),function(method,index){return _c('div',{key:index},[_c('span',[_vm._v(_vm._s(method.method.name)+" "+_vm._s((method.amount*1).toLocaleString('es-MX', { style: 'currency', currency: 'MXN',})))]),_c('br'),_vm._v(" - ")])})}},{key:"item.pdf",fn:function(ref){
var item = ref.item;
return [(item.pdf!=undefined)?_c('v-btn',{staticStyle:{"margin":"auto","display":"block"},attrs:{"href":_vm.liga + 'files/' + item.pdf,"target":"_blank","icon":""}},[_c('v-icon',[_vm._v("mdi-download-circle")])],1):_vm._e()]}},{key:"no-data",fn:function(){return [_vm._v(" No existen registros de cobranza aún ")]},proxy:true},{key:"item.company",fn:function(ref){
var item = ref.item;
return [_c('v-list-item',{staticClass:"px-0",staticStyle:{"min-height":"0px!important","font-size":"14px"},attrs:{"to":{ path: '/clients/client/'+ item.companyID}}},[_vm._v(_vm._s(item.company))])]}}],null,true)}):_vm._e(),_c('v-dialog',{attrs:{"max-width":"900px","persistent":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"bottom":"","color":"#e25200","dark":"","fab":"","fixed":"","right":""}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"white"}},[_vm._v(" mdi-plus ")])],1)]}}]),model:{value:(_vm.createDialog),callback:function ($$v) {_vm.createDialog=$$v},expression:"createDialog"}},[_c('create',{attrs:{"company":_vm.company},on:{"closeCreateDialogCollection":_vm.closeCreateDialogCollection}})],1),_c('v-dialog',{attrs:{"max-width":"900px","persistent":""},model:{value:(_vm.editDialog),callback:function ($$v) {_vm.editDialog=$$v},expression:"editDialog"}},[_c('edit',{attrs:{"collection":_vm.collection},on:{"closeEditDialogCollection":_vm.closeEditDialogCollection}})],1),_c('div',{staticClass:"text-center"},[_c('v-bottom-sheet',{attrs:{"inset":""},model:{value:(_vm.sheet),callback:function ($$v) {_vm.sheet=$$v},expression:"sheet"}},[_c('v-sheet',{staticClass:"text-center",attrs:{"height":"150px"}},[_c('div',{staticClass:"pt-6"},[_vm._v(" ¿Seguro que deseas borrar esta cobranza? ")]),_c('v-btn',{staticClass:"mt-4",attrs:{"text":"","color":"error"},on:{"click":function($event){return _vm.deleteCollection()}}},[_vm._v(" Eliminar ")]),_c('v-btn',{staticClass:"mt-4",attrs:{"text":"","color":"grey"},on:{"click":function($event){return _vm.cancel()}}},[_vm._v(" Cancelar ")])],1)],1)],1),_c('v-snackbar',{attrs:{"color":_vm.snackbar.color},model:{value:(_vm.snackbar.show),callback:function ($$v) {_vm.$set(_vm.snackbar, "show", $$v)},expression:"snackbar.show"}},[_vm._v(" "+_vm._s(_vm.snackbar.message)+" ")]),_c('v-dialog',{attrs:{"fullscreen":"","hide-overlay":"","transition":"dialog-bottom-transition"},model:{value:(_vm.filterMobile),callback:function ($$v) {_vm.filterMobile=$$v},expression:"filterMobile"}},[_c('filterCollections',{staticStyle:{"background":"white"},attrs:{"company":_vm.company},on:{"filtersCollection":_vm.filtersCollection}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }